<template>
  <div class="px-4 py-3">
    <figure class="flex flex-col items-center space-y-2">
      <img v-if="image" :src="image.medium" :alt="content[`image_${item.key}_alt`] ? content[`image_${item.key}_alt`] : ''" class="single-image object-contain">
      <figcaption class="italic">
        {{ content && content.title ? content.title : '(No Caption)' }}
      </figcaption>
    </figure>
  </div>
</template>
<script>
export default {
  friendlyName: 'Image Block',

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    content () {
      return this.item.content
    },

    image () {
      return this.content.images[`image_${this.item.key}`] ? this.content.images[`image_${this.item.key}`] : null
    }
  }
}
</script>
<style scoped>
.single-image {
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 400px;
}
</style>
